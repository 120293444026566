import React from 'react';

import {ButtonCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Query} from "react-apollo";
import {withRouter} from "react-router-dom";

class ButtonCellPreview extends React.Component {

  render() {
    const { cell } = this.props;
    console.log({cell}, cell);
    const { projectId } = this.props.match.params;

    const defaultRender = () => <FontAwesomeIcon icon={'hand-pointer'} />;

    return (
      <SpecificCellPreview
        cell={{...cell, width: cell.coordinates.width, height: cell.coordinates.height}}
        defaultRender={defaultRender}
        query={queries.cells.button.get}
        renderingCondition={(buttonCell) => !!buttonCell.text}
      >
        {(buttonCell) => (
          <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
            {(projectQueryResponse) => {
              if (projectQueryResponse.loading) return null;
              if (projectQueryResponse.error) return `Error!: ${projectQueryResponse.error}`;
              const {project} = projectQueryResponse.data;
              if(!project) return null;

              return (
              <ButtonCell
                preview={true}
                style={{fontSize: '16px'}}
                project={project}
                cell={buttonCell}
              />
              )
            }}
          </Query>
        )
        }
      </SpecificCellPreview>
    );
  }

}

export default withRouter(ButtonCellPreview);
