import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateButtonCell ($input: UpdateButtonCellInput!) {
      updateButtonCell (input: $input) {
        id
        cell_type
        shape
        text
        width_percentage
        opacity
        fit_whole_cell
        button_background_type
        button_background_image_uri
        button_background_color
        background_type
        background_image_uri
        background_video_uri
        background_color
        horizontal_align
        vertical_align
        text_size
        text_color
        font
        click_background_color
        click_text_color
        action_uri
        action_value
        i18n {
          language_code
          text
          button_background_image_uri
          background_image_uri
          background_video_uri
        }
      }
    }`,

  get: gql`
    query ButtonCell($id: ID!) {
      buttonCell(id: $id) {
        id
        shape
        text
        width_percentage
        opacity
        fit_whole_cell
        button_background_type
        button_background_image_uri
        button_background_color
        background_type
        background_image_uri
        background_video_uri
        background_color
        horizontal_align
        vertical_align
        text_size
        text_color
        font
        click_background_color
        click_text_color
        action_uri
        action_value
        i18n {
          language_code
          text
          button_background_image_uri
          background_image_uri
          background_video_uri
        }
      }
    }`
}
