import React from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {stateOrValue} from "../../../utils";
import PaletteColorPicker from "../../PaletteColorPicker";
import ImageUploader from "../../uploaders/ImageUploader";
import _ from "lodash";

class GlobalButtonSettings extends React.Component {

  static defaultProps = {
    canSetToDefault: true
  };

  state = {};
  values = {};

  changeState(change) {
    const { onChange } = this.props;
    console.log('changing', change);
    onChange(change);
    this.setState(change);
}

  componentDidMount() {
    const { canSetToDefault, onChange, currentValue, palette, fonts } = this.props;
    this.values = {
      textSize: stateOrValue(this.state.textSize, currentValue.text_size, !canSetToDefault ? {defaultValue:  1 } : undefined),
      textColor: stateOrValue(this.state.textColor, currentValue.text_color, !canSetToDefault ? {defaultValue:  palette[0] } : undefined),
      font: stateOrValue(this.state.font, currentValue.font, (!canSetToDefault && !!fonts[0]) ? {defaultValue:  fonts[0].name } : undefined),
      opacity: stateOrValue(this.state.opacity, currentValue.opacity, !canSetToDefault ? {defaultValue:  100 } : undefined),
      widthPercentage: stateOrValue(this.state.widthPercentage, currentValue.width_percentage, !canSetToDefault ? {defaultValue:  50 } : undefined),
      shape: stateOrValue(this.state.shape, currentValue.shape, {defaultValue:  !!canSetToDefault ? 'default' : 'rounded_rectangle' }),
      buttonBackgroundType: stateOrValue(this.state.buttonBackgroundType, currentValue.button_background_type, !canSetToDefault ? {defaultValue: 'color'} : undefined),
      buttonBackgroundColor: stateOrValue(this.state.buttonBackgroundColor, currentValue.button_background_color, !canSetToDefault ? {defaultValue:  palette[0] } : undefined),
      buttonBackgroundImageUri: stateOrValue(this.state.buttonBackgroundImageUri, currentValue.button_background_image_uri),
      action_uri: stateOrValue(this.state.action_uri, currentValue.action_uri),
      action_value: stateOrValue(this.state.action_value, currentValue.action_value),
    };

    const fixedValues = _.mapValues(this.values, (value) => value === '' ? null : value);
    onChange(fixedValues);
  }

  render() {
    const { canSetToDefault, intl, currentValue, palette, fonts } = this.props;

    this.values = {
      textSize: stateOrValue(this.state.textSize, currentValue.text_size, !canSetToDefault ? {defaultValue:  1 } : undefined),
      textColor: stateOrValue(this.state.textColor, currentValue.text_color, !canSetToDefault ? {defaultValue:  palette[0] } : undefined),
      font: stateOrValue(this.state.font, currentValue.font, (!canSetToDefault && !!fonts[0]) ? {defaultValue:  fonts[0].name } : undefined),
      opacity: stateOrValue(this.state.opacity, currentValue.opacity, !canSetToDefault ? {defaultValue:  100 } : undefined),
      widthPercentage: stateOrValue(this.state.widthPercentage, currentValue.width_percentage, !canSetToDefault ? {defaultValue:  50 } : undefined),
      shape: stateOrValue(this.state.shape, currentValue.shape, {defaultValue:  !!canSetToDefault ? 'default' : 'rounded_rectangle' }),
      buttonBackgroundType: stateOrValue(this.state.buttonBackgroundType, currentValue.button_background_type, !canSetToDefault ? {defaultValue: 'color'} : undefined),
      buttonBackgroundColor: stateOrValue(this.state.buttonBackgroundColor, currentValue.button_background_color, !canSetToDefault ? {defaultValue:  palette[0] } : undefined),
      buttonBackgroundImageUri: stateOrValue(this.state.buttonBackgroundImageUri, currentValue.button_background_image_uri),
      action_uri: stateOrValue(this.state.action_uri, currentValue.action_uri),
      action_value: stateOrValue(this.state.action_value, currentValue.action_value),
    };

    console.log('global button values = ', this.values);

    return (
      <div>
        <div className={'tsk-input-line'}>
          <label htmlFor={'textSize'}><FormattedMessage id={'cell.text.size.label'} /></label>
          {(!canSetToDefault || (canSetToDefault && !!this.values.textSize)) &&
            <input name={'textSize'}
                   type={'range'}
                   min={0}
                   max={3}
                   step={0.1}
                   value={this.values.textSize}
                   onChange={(e) => this.changeState({textSize : parseFloat(e.target.value)})}
            />
          }
          {canSetToDefault &&
            <span>
              <input type={'checkbox'} checked={!this.values.textSize} onChange={(e) => {
                if(e.target.checked) {
                  this.changeState({textSize: null});
                } else {
                  this.changeState({textSize: 1.0});
                }
              }} />
              <FormattedMessage id={'cell.button.default.label'}/>
            </span>
          }
        </div>
        <div className={'tsk-input-line'}>
          <label htmlFor={'textColor'}><FormattedMessage id={'cell.text.color.label'} /></label>
          <PaletteColorPicker
            color={this.values.textColor}
            disableAlpha={true}
            palette={palette}
            onChange={(color) => {
              const rgb = color.rgb;
              this.changeState({textColor: `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`});
            }}
          />
        </div>
        <div className={'tsk-input-line'}>
          <label htmlFor={'font'}><FormattedMessage id={'cell.text.font.label'} /></label>
          <select
            value={this.values.font}
            onChange={(e) => {
              this.changeState({font: e.target.value});
            }}
          >
            {canSetToDefault &&
            <option value={null}>{intl.formatMessage({id: 'cell.button.default.label'})}</option>
            }
            {fonts.map((font) => <option key={font.id} value={font.name}>{font.name}</option>)}
          </select>
        </div>
        <div className={'tsk-input-line'}>
          <label htmlFor={'widthPercentage'}><FormattedMessage id={'cell.button.width_percentage.label'} /></label>
          <input type={'number'} min={0} max={100} step={1} name={'widthPercentage'} value={this.values.widthPercentage} onChange={(e) => {
            this.changeState({widthPercentage: parseInt(e.target.value)});
          }}/>
        </div>
        <div className={'tsk-input-line'}>
          <label htmlFor={'shape'}><FormattedMessage id={'cell.button.shape.label'} /></label>
          <select
            value={this.values.shape}
            onChange={(e) => {
              this.changeState({shape: e.target.value});
            }}
          >
            {canSetToDefault &&
              <option value={'default'}>{intl.formatMessage({id: 'cell.button.default.label'})}</option>
            }
            <option value={'rectangle'}>{intl.formatMessage({id: 'cell.button.shape.rectangle'})}</option>
            <option value={'rounded_rectangle'}>{intl.formatMessage({id: 'cell.button.shape.roundedrectangle'})}</option>
            <option value={'circle'}>{intl.formatMessage({id: 'cell.button.shape.circle'})}</option>
          </select>
        </div>
        <div className={'tsk-input-line'}>
          <label htmlFor={'opacity'}><FormattedMessage id={'cell.button.opacity.label'} /></label>
          <input type={'number'} min={0} max={100} step={1} name={'opacity'} value={this.values.opacity} onChange={(e) => {
            this.changeState({opacity: parseInt(e.target.value)});
          }}/>
        </div>

        <div className='tsk-input-line'>
          <label>
            Action Uri
          </label>
          <input onChange={(e)=>{this.changeState({action_uri: e.target.value})}} value={this.values.action_uri} type='text'/>
        </div>
        <div className='tsk-input-line'>
          <label>
            Action Value
          </label>
          <input onChange={(e)=>{this.changeState({action_value: e.target.value})}} value={this.values.action_value} type='text'/>
        </div>

        <div className={'tsk-setting-section'}>
          <label><FormattedMessage id={'cell.button.background.section.label'} /></label>
          <ul>
            {canSetToDefault &&
            <li>
              <div className={'tsk-input-line'} onClick={(e) => {
                this.changeState({buttonBackgroundType: 'default'});
              }}>
                <input type="radio" name="buttonBackgroundType" value="default"
                       checked={this.values.buttonBackgroundType === 'default'}
                       readOnly={true}
                />
                <label><FormattedMessage id={'cell.button.default.label'} /></label>
              </div>
            </li>
            }
            <li>
              <div className={'tsk-input-line'} onClick={(e) => {
                this.changeState({buttonBackgroundType: 'color'});
              }}>
                <input type="radio" name="buttonBackgroundType" value="color"
                       checked={this.values.buttonBackgroundType === 'color'}
                       readOnly={true}
                />
                <label htmlFor={'buttonBackgroundColor'}><FormattedMessage id={'cell.text.background.color.label'} /></label>
                <PaletteColorPicker
                  color={this.values.buttonBackgroundColor}
                  disableAlpha={true}
                  palette={palette}
                  onChange={(color) => {
                    const rgb = color.rgb;
                    this.changeState({buttonBackgroundColor: `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`});
                  }}
                />
              </div>
            </li>
            <li>
              <div className={'tsk-input-line'} onClick={(e) => {
                this.changeState({buttonBackgroundType: 'image'});
              }}>
                <input type="radio" name="buttonBackgroundType" value="image"
                       checked={this.values.buttonBackgroundType === 'image'}
                       readOnly={true}
                />
                <label htmlFor={'buttonBackgroundImage'}><FormattedMessage id={'cell.text.background.image.label'} /></label>
                <ImageUploader
                  value={this.values.buttonBackgroundImageUri}
                  onUpload={(imageUri) => {
                    this.changeState({buttonBackgroundImageUri : imageUri});
                  }}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default injectIntl(GlobalButtonSettings);
