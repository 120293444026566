import React from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../../queries";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {stateOrValue} from "../../../utils";
import FileUploader from "../../uploaders/FileUploader";

class PlanCellEditor extends React.Component  {

  state = {
    i18n: {},
    colorBackground : this.props.cell.background_color,
    textColor: this.props.cell.text_form_color,
    selectedColor: this.props.cell.btn_select_color,
    borderColor: this.props.cell.border_form_color,
    background_left : this.props.cell.background_btn_left,
    background_right : this.props.cell.background_btn_right,
    color_left : this.props.cell.color_text_left,
    color_right : this.props.cell.color_text_right,
    isChange: false,
  };

  setI18nState(language, oldState, newState) {
    this.setState({i18n: {...this.state.i18n, [language.code]: {...oldState, ...newState}}});
  }

  render() {
    const { cell, language } = this.props;

    console.log(cell);

    return (
      <Query query={queries.cells.plan.get} variables={{ id: cell.id }}>
        {({ loading, error, data }) => {
         console.log("Loading:", loading);
         console.log("Error:", error);
         console.log("Data:", data);
   
         if (loading) return null;
         if (error) return `Error!: ${error}`;
   
         const { planCell } = data;
         console.log("PlanCell:", planCell);
   
         if (!planCell) return null;

         if((planCell.background_color !== this.state.colorBackground || 
            planCell.text_form_color !== this.state.textColor ||
            planCell.btn_select_color !== this.state.selectedColor ||
            planCell.background_btn_left !== this.state.background_left ||
            planCell.background_btn_right !== this.state.background_right ||
            planCell.color_text_left !== this.state.color_left ||
            planCell.color_text_right !== this.state.color_right) && !this.state.isChange){
            this.setState({
            colorBackground : planCell.background_color,
            textColor: planCell.text_form_color,
            selectedColor: planCell.btn_select_color,
            borderColor: planCell.border_form_color,
            background_left : planCell.background_btn_left,
            background_right : planCell.background_btn_right,
            color_left : planCell.color_text_left,
            color_right : planCell.color_text_right,
          })
        };
          if(!!language) {
            const i18nState = this.state.i18n[language.code] || {};
            const i18nValues = _.filter(planCell.i18n, {language_code: language.code})[0] || {};
            return (
              <Mutation
                mutation={queries.cells.i18n.update}
                refetchQueries={() => [{ query: queries.cells.plan.get, variables: {id: cell.id} }]}
              >
                {(i18nCell) => (
                  <div>

                  </div>
                )}
              </Mutation>
            )
          }
          return (
            <Mutation
              mutation={queries.cells.plan.update}
              refetchQueries={() => [{ query: queries.cells.plan.get, variables: {id: cell.id} }]}
            >
              {(mutationFct) => (
               <div>
                <label>
                  <FormattedMessage id={"plan.edit.label.gltf"}></FormattedMessage>
                </label>
                <FileUploader accept={'.gltf, .glb'} uploadPath='/gltf/' onUpload={(plan) => {
                      const data = plan;
                      mutationFct({ variables: { input:{id: cell.id, plan_uri: data} } });
                    }}>
                </FileUploader>
                <br/>
                <br/>
                <label>
                  <FormattedMessage id={"plan.edit.label.csv"}></FormattedMessage>
                </label>
                <FileUploader cellId={cell.id} accept={'.csv'} uploadPath='/csv_expo/' onUpload={(plan) => {
                      const data = plan;
                      mutationFct({ variables: { input:{id: cell.id, plan_uri: data} } });
                    }}>
                </FileUploader>
                <br/>
                <br/>
                <label>
                  <FormattedMessage id={'plan.edit.label.background'}/>
                </label>
                <input type="color" value={this.state.colorBackground} onChange={(e)=>{this.setState({colorBackground: e.target.value, isChange: true})}} />
                <br/>
                <br/>
                <label>
                  <FormattedMessage id={'plan.edit.label.text'}/>
                </label>
                <input type="color" value={this.state.textColor} onChange={(e)=>{this.setState({textColor: e.target.value, isChange: true})}}/>
                <br/>
                <br/>
                <label>
                  <FormattedMessage id={'plan.edit.label.selected'}/>
                </label>
                <input type="color" value={this.state.selectedColor} onChange={(e)=>{this.setState({selectedColor: e.target.value, isChange: true})}}/>
                <br/>
                <br/>
                <label>
                  <FormattedMessage id={'plan.edit.label.border'}/>
                </label>
                <input type="color" value={this.state.borderColor} onChange={(e)=>{this.setState({borderColor: e.target.value, isChange: true})}}/>
                <br/>
                <label>
                  <FormattedMessage id={'plan.edit.label.background_expo_number'}/>
                </label>
                <input type="color" value={this.state.background_left} onChange={(e)=>{this.setState({background_left: e.target.value, isChange: true})}}/>
                <br/>
                <label>
                  <FormattedMessage id={'plan.edit.label.color_expo_number'}/>
                </label>
                <input type="color" value={this.state.color_left} onChange={(e)=>{this.setState({color_left: e.target.value, isChange: true})}}/>
                <br/>
                <label>
                  <FormattedMessage id={'plan.edit.label.background_expo_hall'}/>
                </label>
                <input type="color" value={this.state.background_right} onChange={(e)=>{this.setState({background_right: e.target.value, isChange: true})}}/>
                <br/>
                <label>
                  <FormattedMessage id={'plan.edit.label.color_expo_hall'}/>
                </label>
                <input type="color" value={this.state.color_right} onChange={(e)=>{this.setState({color_right: e.target.value, isChange: true})}}/>
                <br/>
                <br/>
                <br/>
                <button className="tsk-button" onClick={()=>{mutationFct({variables: {input:{       
                id: cell.id,
                color_text_left: this.state.color_left,
                color_text_right: this.state.color_right,
                background_btn_left: this.state.background_left,
                background_btn_right: this.state.background_right,
                background_color: this.state.colorBackground,
                text_form_color: this.state.textColor,
                border_form_color: this.state.borderColor,
                btn_select_color: this.state.selectedColor}}})}}>
                  <FormattedMessage id={'common.button.submit'}/>
                </button>
               </div>
              )}
            </Mutation>
          )
        }}
      </Query>
    );
  }
}

export default PlanCellEditor;
